import React from "react";

const Button = ({ as, variant, arrow, children, className, ...props }) => {
  const Component = as || "button";

  const variants = {
    default:
      "inline-block bg-white text-gray-800 hover:text-primary-500  border border-gray-200 hover:border-primary-500 font-bold py-3 px-6 rounded-lg transition-colors",
    primary:
      "inline-block bg-primary-500 border border-transparent hover:bg-primary-700 text-white transition-colors font-bold py-3 px-6  rounded-lg",
    secondary:
      "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
    success:
      "bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded",
    warning:
      "bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded",
    danger:
      "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded",
    info: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded",
    yellow:
      "bg-[#ffff00] hover:bg-[#e7e718] text-black font-bold py-2 px-4 rounded transition-colors",
    light:
      "bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow",
    dark: "bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded",
  };

  return (
    <Component className={`${(variants[variant] || variants["default"])} ${className ? className : ''}`} {...props}>
      <span className="flex gap-4 items-center whitespace-nowrap justify-center">
        {children} {arrow && <span>&rarr;</span>}
      </span>
    </Component>
  );
};

export default Button;
