import logo from './devstyle.svg'
import './App.css';
import Button from './Button';
import photo from './mailing.jpg'


function App() {
  return (

    <section
    className={`flex flex-col w-screen h-screen overflow-y-auto bg-[rgba(2,0,33,0.9)] text-gray-800 z-50 inset-0 p-4 md:p-8`}
  >
    <div className="max-w-screen-xl bg-white text-gray-800 rounded-3xl mx-auto p-4 md:p-8  md:my-auto">
      <div className="grid lg:grid-cols-2 gap-8">
        <div>
          <img
            src={logo}
            alt="devstyle - Zapis na newsletter"
            className="my-8"
          />
          <h2 className="text-3xl md:text-4xl font-bold mb-6">
            Dołącz do ponad 40&nbsp;000 programistek i&nbsp;programistów!
          </h2>
          <div className="prose">
            <p>Podaj adres e-mail i... będziemy w kontakcie!</p>
          </div>

            <form
              action="https://app.convertkit.com/forms/5173867/subscriptions"
              className="ck-form my-6 space-y-4 flex flex-col items-stretch w-full max-w-md"
              method="post"
              target="_self"
            >
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                      stroke="#67748E"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <input
                  className="cursor-pointer w-full border border-gray-200 rounded-3xl pl-14 pr-4 py-3 focus:border-gray-500 outline-none"
                  type="text"
                  name="fields[first_name]"
                  placeholder="Twoje Imię"
                  aria-label="Twoje Imię"
                  id="ck-first-name"
                  required
                />
              </div>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                      stroke="#67748E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <input
                  type="email"
                  className="cursor-pointer w-full border border-gray-200 rounded-3xl pl-14 pr-4 py-3 focus:border-gray-500 outline-none"
                  name="email_address"
                  placeholder="Adres e-mail"
                  aria-label="Email"
                  id="ck-email"
                  required
                />
              </div>
              <div>
                <Button className="w-full" variant="primary" type="submit">
                  <span className="flex gap-2">
                    Zapisz się
                    <span>&rarr;</span>
                  </span>
                </Button>
              </div>
            </form>

          <p className="text-xs">
            Zapisując się do społeczności devstyle.pl zgadzasz się na
            przetwarzanie Twoich danych osobowych w celu wysyłania na
            wskazany przez Ciebie adres e-mail informacji handlowych o
            nowościach, promocjach, produktach i usługach związanych z
            serwisem devstyle.pl. Będzie to marketing bezpośredni, do
            realizacji którego wykorzystamy Twoje telekomunikacyjne
            urządzenia końcowe. Administratorem Twoich danych osobowych
            będzie DEVSTYLE spółka z ograniczona odpowiedzialnością z
            siedzibą w Białymstoku (15-215) przy ul. Konopnickiej 14/8, NIP:
            5423453088 (dawniej Maciej Aniserowicz prowadzący jednoosobową
            działalność gospodarczą, NIP 5422824401). Przysługuje Tobie
            prawo do cofnięcia zgody, żądania wglądu do Twoich danych,
            wniesienia sprzeciwu co do ich przetwarzania, sprostowania,
            usunięcia i ograniczenia przetwarzania. Więcej informacji o tym
            jak przetwarzamy Twoje dane znajdziesz na <a href="https://devstyle.pl/rodo">devstyle.pl/rodo</a>.
          </p>
        </div>
        <div className="hidden lg:block rounded-3xl overflow-hidden">
          <img
            className="object-cover w-full h-full"
            src={photo}
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  );
}

export default App;
